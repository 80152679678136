@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.montserrat {
  font-family: "Montserrat", sans-serif;
}

html {
  height: 100%;
  /* height: 100%; */
}

body {
  position: relative;
  font-family: "Montserrat", sans-serif;
  -ms-overflow-style: none; /* Hide scrollbars on IE and Edge */
  scrollbar-width: none; /* Hide scrollbars on Firefox */
  height: 100%;
}

body::-webkit-scrollbar {
  display: none; /* Hide scrollbars on Chrome, Safari, and Opera */
}

::-webkit-scrollbar {
  display: none;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* #page {
  margin-top: 90px;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
} */

@keyframes slide-in {
  from {
    transform: translateX(-290px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-290px);
  }
}

.calc-width {
  width: calc((100vw - 1024px) / 2 - 4vw);
}

@layer utilities {
  .animate-slide-out {
    animation: slide-out 0.3s ease;
  }

  .animate-slide-in {
    animation: slide-in 0.3s ease;
  }
}

/* .faded-edges {
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    black 0%,
    black 100%,
    transparent 100%
  );
  mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    black 0%,
    black 92.5%,
    transparent 100%
  );
} */

.aspect-ratio-155 {
  aspect-ratio: 155/100;
}

@keyframes pulse-color {
  0% {
    opacity: 1; /* text-gray-800 */
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1; /* text-gray-800 */
  }
}

@keyframes pulse-box-shadow {
  0% {
    box-shadow: 0 0 22.5px inset rgb(115, 115, 115);
  }
  50% {
    box-shadow: 0 0 22.5px inset rgba(115, 115, 115, 0.4);
  }
  100% {
    box-shadow: 0 0 22.5px inset rgb(115, 115, 115);
  }
}

.animate-c-shadow {
  animation: pulse-box-shadow 2.25s linear infinite;
}

@keyframes rotate-y {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.animate-pulse {
  animation: pulse-color 3s cubic-bezier() infinite;
}

.animate-rotate-y {
  animation: rotate-y 4.5s linear infinite;
}

.snap-x {
  scroll-snap-type: x mandatory;
}

.snap-mandatory {
  scroll-snap-type: both mandatory;
}

.snap-start {
  scroll-snap-align: start;
}

.snap-always {
  scroll-snap-stop: always;
}

/* .c-shadow {
  box-shadow: 0 0 22.5px inset rgb(115, 115, 115);
} */

.bg444 {
  background-color: rgb(10, 12, 17);
}

#sphere::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* bottom: -55%; */
  background: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 0) 20%,
    rgb(0, 0, 0) 30%
  );
  pointer-events: none;
}
